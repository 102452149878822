import ApplicationSideBar from '@pretto/bricks/app/application/components/ApplicationSideBar'
import NavBar from '@pretto/bricks/components/bars/NavBar'
import * as helpers from '@pretto/bricks/components/helpers'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import SecondNav from '@pretto/bricks/website/about/components/SecondNav'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const ApplicationSecondNav = styled(SecondNav).attrs(({ theme }) => ({ activeColor: theme.colors.primary2 }))`
  ${helpers.boxShadow('card')};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.neutral1};
`

const Container = styled.div`
  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: ${g(40)} 1fr;
  }
`

export const Layout = ({ children, navigationItems, pathname }) => (
  <>
    <NavBar goBackProps={{ href: '/', label: 'Dossier' }} pathname={pathname} />

    <Container>
      <Responsive max="tablet">
        <ApplicationSecondNav items={navigationItems} />
      </Responsive>

      <Responsive min="tablet">
        <ApplicationSideBar items={navigationItems} />
      </Responsive>

      {children}
    </Container>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navigationItems: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired,
}
