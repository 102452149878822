export const useTracking =
  () =>
  (...args) => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('Track', ...args)
    }

    if (process.env.NODE_ENV === 'production') {
      // analytics.track(...args)
    }
  }
