import * as oleenApi from '@pretto/move-app-client/src/api/lib/oleenApiClient'
import { LoginCheck as LoginCheckView, LoginCheckViewProps } from '@pretto/move-app-client/src/auth/views/loginCheck'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from './context'

export const LoginCheck: React.FC = () => {
  const navigate = useNavigate()
  const { phone, setToken } = useAuth()
  const [error, setError] = useState<LoginCheckViewProps['error']>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isRetrying, setIsRetrying] = useState(false)
  const [bannerMessage, setBannerMessage] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!phone) {
      navigate('/login', { replace: true })
    }
  }, [])

  const onRetry = async () => {
    if (phone) {
      setIsRetrying(true)
      await oleenApi.startOtp(phone)
      setIsRetrying(false)
    }
  }

  const onBack = () => navigate('/login', { replace: true })

  const onSubmit = async ({ code }: { code: string }) => {
    if (!phone) return

    setIsLoading(true)
    const response = await oleenApi.checkOtp(phone, code)
    setIsLoading(false)

    if (response === 'unauthorized' || response === 'not_found') {
      return setError('invalid')
    }

    if (response === 'conflict') {
      return setBannerMessage(
        'Nous rencontrons des difficultés pour afficher votre espace client. Merci de contacter votre courtier.'
      )
    }

    if (response === 'gone') {
      return setBannerMessage(
        'Code expiré. Veuillez demander l’envoi d’un nouveau code en cliquant sur “Réessayer”. '
      )
    }

    if (!response) return

    setToken(response.token)
    navigate('/', { replace: true })
  }

  return (
    <LoginCheckView
      onSubmit={onSubmit}
      error={error}
      onRetry={onRetry}
      isLoading={isLoading}
      isRetrying={isRetrying}
      onBack={onBack}
      bannerMessage={bannerMessage}
    ></LoginCheckView>
  )
}
