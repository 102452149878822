import Link from '@pretto/bricks/website/utility/Link'

import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'

import { Bank, Burden, Crown, ExclamationMarkTriangle, Folder, House, TwoPeople, UserCircle } from '@pretto/picto'

export const schema = [
  ({ docs }, userContext, trackAction) => {
    const handleClick = () => {
      trackAction('APPLICATION_NOTICE_EVENT', {
        application_notice_event_origin: 'inner_link',
        application_notice_event_type: 'click',
        application_notice_type: 'invalid_documents',
      })
    }

    return (
      docs.some(document => convertStatusSlug(document.document_status) === 'invalid') && {
        cardType: 'notice',
        children: (
          <>
            Certains de vos documents sont invalides.{' '}
            <Link href="/documents?status=invalid" onClick={handleClick}>
              Consulter
            </Link>
          </>
        ),
        picto: () => ExclamationMarkTriangle,
        type: 'error',
      }
    )
  },
  {
    description: 'Vos informations personnelles et professionnelles',
    href: '/mortgagor',
    id: 'mortgagor',
    onClick: (props, step, data, userContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'mortgagor' })
    },
    picto: () => UserCircle,
    title: (props, step, data, { accounts }) => accounts[0].name,
  },
  ({
    project: {
      profile: { mortgagors },
    },
  }) =>
    mortgagors.length > 1 && {
      description: 'Ses informations personnelles et professionnelles',
      href: '/comortgagor',
      id: 'comortgagor',
      onClick: (props, step, data, userContext, trackAction) => () => {
        trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'comortgagor' })
      },
      picto: () => UserCircle,
      title: (props, step, data, { accounts }) => accounts[1].name,
    },
  {
    description: 'Informations sur votre situation familiale et maritale',
    href: '/general',
    id: 'general',
    onClick: (props, step, data, userContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'general' })
    },
    picto: () => TwoPeople,
    title: 'Foyer',
  },
  {
    description: (props, step, { project: { project_kind } }) =>
      project_kind === 'purchase'
        ? 'Remplissez les infos concernant votre futur bien.'
        : 'Remplissez les infos concernant le crédit à renégocier',
    href: (props, step, { project: { project_kind } }) => `/${project_kind}`,
    id: (props, step, { project: { project_kind } }) => project_kind,
    onClick:
      (props, step, { project: { project_kind } }, userContext, trackAction) =>
      () => {
        trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: project_kind })
      },
    picto: () => House,
    title: 'Projet',
  },
  {
    description: 'Relevé de comptes et banques dont vous êtes déjà client',
    href: '/banks',
    id: 'banks',
    onClick: (props, step, data, userContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'banks' })
    },
    picto: () => Bank,
    title: 'Comptes',
  },
  {
    description: 'Vos comptes d’épargne, actions, PEL, assurance vie etc.',
    href: '/estate',
    id: 'estate',
    onClick: (props, step, data, userContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'estate' })
    },
    picto: () => Crown,
    title: 'Épargne & Patrimoine',
  },
  {
    description: (props, step, { project: { project_kind } }) =>
      `${
        project_kind === 'renegotiation'
          ? "Avez-vous d'autres crédits que vous continuerez à rembourser ?"
          : 'Avez-vous des crédits que vous continuerez à rembourser ?'
      }`,
    href: '/credits',
    id: 'credits',
    isActive: (
      props,
      step,
      {
        project: {
          profile: { credits },
        },
      }
    ) => credits && credits.length > 0,
    onClick: (props, step, data, userContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'credits' })
    },
    picto: () => Burden,
    title: (props, step, { project: { project_kind } }) =>
      `${project_kind === 'renegotiation' ? 'Autres crédits en cours' : 'Crédits en cours'}`,
  },
  {
    cardType: 'block',
    description: (
      <>
        Envoyez ici <u>vos documents justificatifs</u> en toute sécurité.
      </>
    ),
    href: '/documents',
    id: 'documents',
    onClick: (props, step, data, userContext, trackAction) => () => {
      trackAction('APPLICATION_CARD_CLICKED', { application_card_identifier: 'documents' })
    },
    picto: () => Folder,
    title: 'Documents',
  },
]
