import IdTokenVerifier from 'idtoken-verifier'

export const STORAGE_AUTH_TOKEN_KEY = 'auth_token'

// Helper class to handle auth token storage
export class TokenStorage {
  storage: Storage

  // Storage should be either `localStorage` or `sessionStorage` objects
  constructor(storage: Storage) {
    this.storage = storage
  }

  static build(impersonationToken: string | null = null) {
    if (impersonationToken) {
      localStorage.removeItem(STORAGE_AUTH_TOKEN_KEY) // impersonators might have a token in localStorage
      sessionStorage.setItem(STORAGE_AUTH_TOKEN_KEY, impersonationToken)
    }

    return TokenStorage.load()
  }

  static load() {
    return sessionStorage.getItem(STORAGE_AUTH_TOKEN_KEY) !== null
      ? new TokenStorage(sessionStorage)
      : new TokenStorage(localStorage)
  }

  // Helper to retrieve any token present on the browser storages.
  static getToken() {
    return new TokenStorage(localStorage).getToken() || new TokenStorage(sessionStorage).getToken()
  }

  getToken() {
    return this.storage.getItem(STORAGE_AUTH_TOKEN_KEY) || null
  }

  setToken(token: string) {
    this.storage.setItem(STORAGE_AUTH_TOKEN_KEY, token)
  }

  clearToken() {
    this.storage.removeItem(STORAGE_AUTH_TOKEN_KEY)
  }

  hasToken() {
    return this.getToken() !== null
  }
}

export const getImpersonationToken = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const searchParamsToken = searchParams.get('impersonate')
  return searchParamsToken
}

export const decodeToken = (token: string) => {
  const decoder = new IdTokenVerifier({ issuer: 'https://pretto-users.eu.auth0.com', audience: '' })
  const { payload } = decoder.decode(token)

  return payload
}
