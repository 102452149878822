import * as filters from '@pretto/app-core/lib/filters'

import { House } from '@pretto/picto'

const localisation = {
  fieldProps: {
    placeholder: '3 Place Jean-Paul-Sartre-et-Simone-de-Beauvoir',
    searchfieldPlaceholder: 'Saisissez une adresse',
  },
  label: 'Adresse du bien',
  name: 'good.localisation',
  type: 'address',
}

const numberOfRooms = {
  fieldProps: {
    placeholder: '5',
    range: { max: 20, min: 1 },
  },
  label: 'Le nombre de pièces',
  name: 'good.number_of_rooms',
  type: 'range',
}

const surface = {
  fieldProps: {
    placeholder: '42',
    range: { max: 10000, min: 1 },
  },
  label: 'La surface habitable en m2',
  name: 'good.surface',
  type: 'range',
}

const propertySection = {
  fields: [localisation, numberOfRooms, surface],
  title: 'Informations sur le bien',
}

const defaultPage = {
  decoder: filters.applyFilters([filters.applyFilter('good.localisation', filters.formatDecodeAddress)]),
  sections: [propertySection],
  title: 'Projet',
}

export const step = {
  condition: ({ project: { project_kind } }) => project_kind === 'purchase',
  documentsParameters: {
    category: 'property',
  },
  pages: [defaultPage],
  sideBarParameters: {
    label: 'Projet',
    picto: House,
    sideBarOrder: 3,
    url: '/purchase',
  },
}
