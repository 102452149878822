import temporal from '@pretto/bricks/core/utility/temporal'
import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'

import { UploadFileStatus, useUpload } from '@pretto/app-core/application/lib/useUpload'
import { useNotifications } from '@pretto/app-core/notifications/notifications'

import { useTracking } from '@pretto/move-app-client/src/analytics/lib/useTracking'
import { fragmentSupportingFile } from '@pretto/move-app-client/src/application/containers/applicationContext'
import { useApplication } from '@pretto/move-app-client/src/application/lib/useApplication'
import { useUser } from '@pretto/move-app-client/src/user/lib/useUser'

import { gql, useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { createContext, useMemo } from 'react'

export const Context = createContext()

export const Provider = ({ children }) => {
  const { data } = useApplication()

  const { notify } = useNotifications()

  const [confirmUpload] = useMutation(mutationConfirmUpload)
  const [removeDocument] = useMutation(mutationRemoveDocument)

  const trackAction = useTracking()

  const initialFiles =
    data.docs?.reduce(
      (previous, { slug, supporting_files }) =>
        supporting_files.reduce((previous, supportingFile) => {
          return [
            ...previous,
            {
              fileName: supportingFile.original_filename,
              localId: supportingFile.id,
              progress: 100,
              remoteId: supportingFile.id,
              slug,
              status: supportingFile.treated ? UploadFileStatus.Stale : UploadFileStatus.Uploaded,
              uploadedAt: supportingFile.uploaded_at,
            },
          ]
        }, previous),
      []
    ) ?? []

  const { files, addFile, completeFile, deleteFile, incompleteFile, removeFile, uploadFile } = useUpload(initialFiles)

  const { projectId } = useUser()

  const uploadingFilesLength = useMemo(
    () => files.filter(({ status }) => status === UploadFileStatus.Uploading).length,
    [files]
  )

  useUpdateEffect(() => {
    if (uploadingFilesLength === 0) {
      notify('Nous avons bien reçu vos documents !')
    }
  }, [uploadingFilesLength])

  const handleDelete = async (currentDocument, uploadFile) => {
    try {
      removeFile(uploadFile.localId)

      const {
        data: {
          delete_upload: { success },
        },
      } = await removeDocument({
        update: (
          cache,
          {
            data: {
              delete_upload: { success },
            },
          }
        ) => {
          if (!success) {
            return
          }

          cache.modify({
            id: cache.identify(currentDocument),
            fields: {
              document_status(documentStatus, { readField }) {
                const supportingFiles = readField('supporting_files') ?? []
                const newSupportingFiles = supportingFiles.filter(
                  supportingFileRef => uploadFile.remoteId !== readField('id', supportingFileRef)
                )

                if (newSupportingFiles.length > 0) {
                  return documentStatus
                }

                return 'empty'
              },
              supporting_files(supportingFiles = [], { readField }) {
                return supportingFiles.filter(
                  supportingFileRef => uploadFile.remoteId !== readField('id', supportingFileRef)
                )
              },
            },
          })
        },
        variables: { id: uploadFile.remoteId },
      })

      if (!success) {
        throw new Error()
      }

      deleteFile(uploadFile.localId)

      notify(`Votre fichier ${uploadFile.fileName} a bien été supprimé !`)

      trackAction('DOCUMENTS_DOCUMENT_DELETED', { kind: currentDocument.slug })
    } catch (error) {
      const notification = notify(
        notification => {
          const handleRetry = () => {
            notification.pop()
            handleDelete(currentDocument, uploadFile)
          }

          return (
            <>
              Une erreur est survenue dans la suppression du fichier {uploadFile.fileName}.{' '}
              <button onClick={handleRetry} type="button">
                Réessayez
              </button>
              .
            </>
          )
        },
        {
          delay: null,
          type: 'error',
        }
      )

      incompleteFile(uploadFile.localId, () => {
        notification.pop()
        handleDelete(currentDocument, uploadFile)
      })
    }
  }

  const handleUpload = (currentDocument, signedFiles) => {
    signedFiles.forEach(async signedFile => {
      addFile(signedFile.file.name, signedFile.id, currentDocument.slug ?? '')

      try {
        const key = await uploadFile(signedFile.file, signedFile.id, projectId)

        const uploadedAt = temporal().toISOString()

        const {
          data: {
            confirm_upload: { fileId, success },
          },
        } = await confirmUpload({
          update(
            cache,
            {
              data: {
                confirm_upload: { fileId, success },
              },
            }
          ) {
            if (!success) {
              return
            }

            const __typename = 'DocsListFiles'

            cache.modify({
              id: cache.identify(currentDocument),
              fields: {
                document_status() {
                  return 'pending'
                },
                supporting_files(supportingFiles = []) {
                  const supportingFile = cache.writeFragment({
                    id: cache.identify({ __typename, id: fileId }),
                    data: {
                      __typename,
                      id: fileId,
                      original_filename: signedFile.file.name,
                      treated: false,
                      uploaded_at: uploadedAt,
                    },
                    fragment: fragmentSupportingFile,
                  })

                  return [...supportingFiles, supportingFile]
                },
              },
            })
          },
          variables: {
            key,
            kindHint: currentDocument.kind,
            meta: {
              bank: currentDocument?.meta?.bank,
              month: currentDocument?.meta?.month,
              mortgagor: currentDocument?.meta?.mortgagor,
              year: currentDocument?.meta?.year,
            },
            originalFilename: signedFile.file.name,
          },
        })

        if (!success) {
          throw new Error()
        }

        completeFile(signedFile.id, fileId, uploadedAt)

        trackAction('DOCUMENTS_DOCUMENT_UPLOADED', { kind: currentDocument.slug })
      } catch (error) {
        const notification = notify(
          notification => {
            const handleRetry = () => {
              notification.pop()
              handleUpload(currentDocument, [signedFile])
            }

            return (
              <>
                Une erreur est survenue dans l’envoi du fichier {signedFile.file.name}.{' '}
                <button onClick={handleRetry} type="button">
                  Réessayez
                </button>
                .
              </>
            )
          },
          {
            delay: null,
            type: 'error',
          }
        )

        incompleteFile(signedFile.id, () => {
          notification.pop()
          handleUpload(currentDocument, [signedFile])
        })
      }
    })
  }

  const context = useMemo(
    () => ({
      files,
      onDelete: handleDelete,
      onUpload: handleUpload,
    }),
    [files]
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

const mutationConfirmUpload = gql`
  mutation confirmUpload($key: String!, $kindHint: String!, $meta: DocsMeta!, $originalFilename: String!) {
    confirm_upload(key: $key, kind_hint: $kindHint, meta: $meta, original_filename: $originalFilename) {
      error
      fileId
      success
    }
  }
`

const mutationRemoveDocument = gql`
  mutation removeDocument($id: String!) {
    delete_upload(id: $id) {
      error
      success
    }
  }
`
