import ButtonComponent from '@pretto/bricks/components/buttons/Button'
import ButtonInlineComponent from '@pretto/bricks/components/buttons/ButtonInline'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { column, Grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'
import styled from 'styled-components'

export const InternalError = ({
  description = 'Quelque chose ne s’est pas passé comme prévu.\nNotre équipe va résoudre le problème dès que possible.',
  isRefreshable,
  title = 'Oh, mince !',
}) => {
  const handleRefresh = () => {
    window.location.reload()
  }

  const handleReset = event => {
    event.preventDefault()

    window.location.href = event.currentTarget.href
  }

  return (
    <Page>
      <Content>
        <Image />

        <Title>{title}</Title>

        <Description>{nl2br(description)}</Description>

        <Button href="/" isSecondary onClick={handleReset}>
          Revenir à la page d’accueil
        </Button>

        <br />

        {isRefreshable && (
          <ButtonInline isSecondary onClick={handleRefresh}>
            Rafraîchir la page
          </ButtonInline>
        )}
      </Content>
    </Page>
  )
}

InternalError.defaultProps = {
  isRefreshable: false,
}

InternalError.propTypes = {
  description: PropTypes.string,
  isRefreshable: PropTypes.bool,
  title: PropTypes.string,
}

const Button = styled(ButtonComponent)`
  margin-top: ${g(3)};
`

const ButtonInline = styled(ButtonInlineComponent)`
  margin-top: ${g(4)};
`

const Content = styled.div`
  ${column([2, 4])};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([4, 4])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([5, 6])};
  }
`

const Description = styled.p`
  margin-top: ${g(2)};
`

const Image = styled(ImageComponent).attrs({
  options: {
    height: ng(22),
    width: ng(22),
  },
  path: 'v1637683132/zen/static-assets/illustrations/660x660/twoPeopleInFrontOfMachine.svg',
})`
  height: ${g(22)};
  width: ${g(22)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: ${g(30)};
    width: ${g(30)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: ${g(35)};
    width: ${g(35)};
  }
`

const Page = styled(Grid)`
  padding-bottom: ${g(8)};
  padding-top: ${g(7)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: ${g(16)};
  }
`

export const Title = styled.h1`
  ${typo.heading48};
  margin-top: ${g(6)};
`
