import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'
import { getDocumentsProgress } from '@pretto/app-core/application/lib/getDocumentsProgress'
import { getNavigationItems } from '@pretto/app-core/application/lib/getNavigationItems'
import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import * as forms from '@pretto/move-app-client/src/application/config'
import { useUser } from '@pretto/move-app-client/src/user/lib/useUser'
import { Folder } from '@pretto/picto'

import { gql, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { createContext } from 'react'
import { useLocation } from 'react-router-dom'

export const Context = createContext()

export const Provider = ({ children }) => {
  const { data, loading } = useQuery(query)

  const location = useLocation()

  const userContext = useUser()

  useLoading(loading)

  if (loading) {
    return null
  }

  const documentsProgress = getDocumentsProgress(data.docs)

  const value = {
    completionPercentage: 0,
    data,
    documentsProgress,
    navigationItems: [
      ...getNavigationItems(forms, location.pathname, data, userContext),
      {
        isActive: location.pathname === '/documents',
        isComplete: documentsProgress.current === documentsProgress.target,
        isErrored: data.docs.some(({ document_status }) => convertStatusSlug(document_status) === 'invalid'),
        label: 'Documents',
        picto: Folder,
        url: '/documents',
      },
    ],
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const fragmentSupportingFile = gql`
  fragment SupportingFile on DocsListFiles {
    id
    original_filename
    treated
  }
`

export const query = gql`
  query application {
    docs(full_list: true) {
      docDescription: comments
      document_status
      id
      error_message
      kind
      meta {
        month
        mortgagor
        year
        bank
      }
      mortgagor
      slug
      supporting_files {
        ...SupportingFile
      }
      title
    }
    project {
      facts {
        bridge_credit {
          payment
        }
      }
      good {
        localisation {
          city
          country
          street
          zipcode
        }
        number_of_rooms
        property_kind
        surface
      }
      profile {
        children
        credits {
          end_date
          id
          kind
          payment
          remaining_principal
          property_id
          bridge
        }
        properties {
          id
          name
          location {
            city
            country
            street
            zipcode
          }
          owners_ids
          purchase_price
          purchase_year
          estimated_value
        }
        savings {
          amount
          id
          kind
          owner
        }
        joint_banks
        live_together
        married_together
        mortgagors {
          address {
            city
            country
            street
            zipcode
          }
          banking_incident
          birthdate
          birthplace {
            city
            country
            zipcode
          }
          bonus {
            amount
            period
            taxes
          }
          civil_status
          contract
          current_banks
          housing
          id
          incomes {
            amount
            id
            kind
            property_id
          }
          job {
            address {
              city
              country
              street
              zipcode
            }
            employer
            start_date
            title
          }
          mandate1
          mandate2
          mandate3
          mandate4
          mandate5
          mandate6
          nationality
          rent
          salary {
            amount
            period
            taxes
          }
        }
      }
      project_kind
      purchase {
        agreement_sign_date
        bridge {
          estimated_value
        }
        build_price
        land_price
        maturity
        property_price
        works_price
      }
      renegotiation {
        bank
        end_date
        rate
        remaining_principal
      }
    }
  }
  ${fragmentSupportingFile}
`
