import * as oleenApi from '@pretto/move-app-client/src/api/lib/oleenApiClient'
import { Login as LoginView } from '@pretto/move-app-client/src/auth/views/login'
import { usePhoneField } from '@pretto/move-app-client/src/lib/form/usePhoneField'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from './context'

export const Login = () => {
  const navigate = useNavigate()
  const { setPhone, logout } = useAuth()
  const [isUnknown, setIsUnknown] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [bannerMessage, setBannerMessage] = useState<string | undefined>(undefined)
  const [message, setMessage] = useState<undefined | string>(undefined)
  const [state, setState] = useState<undefined | 'error'>(undefined)

  useEffect(() => logout())

  const {
    check: phoneFieldCheck,
    component: phoneFieldComponent,
    value: phone,
  } = usePhoneField({
    inputProps: {
      placeholder: 'numéro de téléphone',
      required: true,
    },
    state,
    message,
    placeholderAsLabel: true,
    size: 'large',
  })

  useEffect(() => {
    setState(undefined)
    setMessage(undefined)
  }, [phone])

  useEffect(() => {
    if (isUnknown) {
      setState('error')
      setMessage('Compte introuvable')
    }
  }, [isUnknown])

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const isValid = [phoneFieldCheck()].every(Boolean)
    if (!isValid) {
      return
    }

    setIsLoading(true)
    const response = await oleenApi.startOtp(phone)
    setIsLoading(false)

    if (response === 'not_found') {
      return setIsUnknown(true)
    }

    if (response === 'conflict') {
      return setBannerMessage(
        'Nous rencontrons des problèmes pour afficher votre espace client, veuillez contacter votre courtier.'
      )
    }

    setPhone(phone)
    navigate('/loginCheck', { replace: true })
  }

  return (
    <LoginView
      phoneFieldComponent={phoneFieldComponent}
      onSubmit={onSubmit}
      isLoading={isLoading}
      bannerMessage={bannerMessage}
    />
  )
}
