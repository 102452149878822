import * as filters from '@pretto/app-core/lib/filters'
import { isFieldValid } from '@pretto/app-core/lib/isFieldValid'

import BANKS from '@pretto/config/banks.json'
import { Bank } from '@pretto/picto'

export const withRegionalBanks =
  ({ name, label }) =>
  values => {
    const currentBanks = values[name] || []
    const currentBankGroups = currentBanks.filter(bank => BANKS.some(({ group }) => group === bank))
    const regionalBanksFields = currentBankGroups.map(group => ({
      fieldProps: {
        group,
      },
      key: group,
      label: label(group),
      name,
      type: 'banks',
    }))

    return regionalBanksFields
  }

const comortgagorRegionalAccounts = withRegionalBanks({
  label:
    name =>
    (values, data, { accounts }) =>
      `${name} : les caisses régionales dans lesquelles ${accounts[1].name} a un compte courant`,
  name: 'profile.mortgagors[1].current_banks',
})

const mortgagorRegionalAccounts = withRegionalBanks({
  label:
    name =>
    (values, data, { accounts }) =>
      `${name} : les caisses régionales dans lesquelles ${accounts[0].name} a un compte courant`,
  name: 'profile.mortgagors[0].current_banks',
})

const mortgagorsRegionalAccounts = withRegionalBanks({
  label: name => `${name} : les caisses régionales dans lesquelles les emprunteurs ont des comptes joints`,
  name: 'profile.joint_banks',
})

const mortgagorsJointAccounts = {
  label: (values, data, { accounts }) =>
    `Les banques dans lesquelles ${accounts[0].name} et ${accounts[1].name} ont des comptes joints`,
  name: `profile.joint_banks`,
  optional: values =>
    isFieldValid(values['profile.mortgagors[0].current_banks']) &&
    isFieldValid(values['profile.mortgagors[1].current_banks']),
  type: 'banks',
}

const mortgagorPersonalAccounts = {
  label: (values, data, { accounts }) => `Les banques dans lesquelles ${accounts[0].name} a un compte courant`,
  name: 'profile.mortgagors[0].current_banks',
  optional: values => isFieldValid(values['profile.joint_banks']),
  type: 'banks',
}

const mortgagorOverdraft = {
  fieldProps: {
    options: [
      { id: 'm1t', label: 'Oui', value: 'true' },
      { id: 'm1f', label: 'Non', value: 'false' },
    ],
  },
  label: (values, data, { accounts }) =>
    `${accounts[0].name} a-t-il (elle) été à découvert au cours des trois derniers mois ?`,
  name: 'profile.mortgagors[0].banking_incident',
  type: 'radio',
}

const comortgagorPersonalAccounts = {
  label: (values, data, { accounts }) => `Les banques dans lesquelles ${accounts[1].name} a un compte courant`,
  name: 'profile.mortgagors[1].current_banks',
  optional: values => isFieldValid(values['profile.joint_banks']),
  type: 'banks',
}

const comortgagorOverdraft = {
  condition: (values, data, { hasComortgagor }) => hasComortgagor,
  fieldProps: {
    options: [
      { id: 'cm1t', label: 'Oui', value: 'true' },
      { id: 'cm1f', label: 'Non', value: 'false' },
    ],
  },
  label: (values, data, { accounts }) =>
    `${accounts[1].name} a-t-il (elle) été à découvert au cours des trois derniers mois ?`,
  name: 'profile.mortgagors[1].banking_incident',
  type: 'radio',
}

const overdraftSection = () => ({
  fields: [mortgagorOverdraft, comortgagorOverdraft],
  title: 'Découvert',
})

const jointAccountsSection = values => ({
  condition: (data, { hasComortgagor }) => hasComortgagor,
  fields: [mortgagorsJointAccounts, ...mortgagorsRegionalAccounts(values)],
  title: 'Comptes joints',
})

const mortgagorPersonalAccountsSection = values => ({
  fields: [mortgagorPersonalAccounts, ...mortgagorRegionalAccounts(values)],
  title: (values, data, { accounts }) => `Les comptes courants de ${accounts[0].name}`,
})

const comortgagorPersonalAccountsSection = values => ({
  condition: (data, { hasComortgagor }) => hasComortgagor,
  fields: [comortgagorPersonalAccounts, ...comortgagorRegionalAccounts(values)],
  title: (values, data, { accounts }) => `Les comptes courants de ${accounts[1].name}`,
})

export const defaultPage = {
  decoder: filters.applyFilters([
    filters.applyFilter('profile.mortgagors[0].current_banks', filters.formatDecodeBanks),
    filters.applyFilter('profile.mortgagors[1].current_banks', filters.formatDecodeBanks),
    filters.applyFilter('profile.joint_banks', filters.formatDecodeBanks),
  ]),
  encoder: filters.applyFilters([
    filters.applyFilter('profile.mortgagors[0].current_banks', filters.formatEncodeBanks),
    filters.applyFilter('profile.mortgagors[1].current_banks', filters.formatEncodeBanks),
    filters.applyFilter('profile.joint_banks', filters.formatEncodeBanks),
  ]),
  sections: [
    overdraftSection,
    jointAccountsSection,
    mortgagorPersonalAccountsSection,
    comortgagorPersonalAccountsSection,
  ],
  title: 'Comptes',
}

export const step = {
  documentsParameters: {
    category: 'banks',
  },
  pages: [defaultPage],
  sideBarParameters: {
    label: 'Comptes',
    picto: Bank,
    sideBarOrder: 4,
    url: '/banks',
  },
}
