import { formatSection } from '@pretto/app-core/application/lib/formatSection'
import { getDocumentsForFilters } from '@pretto/app-core/application/lib/getDocumentsForFilters'
import { getDocumentsGroups } from '@pretto/app-core/application/lib/getDocumentsGroups'
import { getDropdownLabel } from '@pretto/app-core/application/lib/getDropdownLabel'
import { getFilters } from '@pretto/app-core/application/lib/getFilters'
import { getSortedDocuments } from '@pretto/app-core/application/lib/getSortedDocuments'
import { Documents as DocumentsComponent } from '@pretto/app-core/application/views/documents/Documents'

import { useTracking } from '@pretto/move-app-client/src/analytics/lib/useTracking'
import { schema } from '@pretto/move-app-client/src/application/config/filters'
import { Upload } from '@pretto/move-app-client/src/application/containers/upload'
import { useApplication } from '@pretto/move-app-client/src/application/lib/useApplication'
import { useUser } from '@pretto/move-app-client/src/user/lib/useUser'

import qs from 'qs'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export const Documents = () => {
  const { data } = useApplication()

  const location = useLocation()

  const navigate = useNavigate()

  const params = useParams()

  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(qs.parse(location.search, { ignoreQueryPrefix: true }))

  const trackAction = useTracking()

  const userContext = useUser()

  useEffect(() => {
    const filters = qs.parse(location.search, { ignoreQueryPrefix: true })

    trackAction('DOCUMENTS_FILTER_CHANGED')

    setSelectedFilters(filters)
    handleClose()
  }, [location.search])

  const applyQueryParameters = parameters => {
    // eslint-disable-next-line no-unused-vars
    const { status, ...existingParameters } = qs.parse(location.search, { ignoreQueryPrefix: true })
    const search = qs.stringify({ ...existingParameters, ...parameters }, { addQueryPrefix: true })

    navigate(`${location.pathname}${search}`, { replace: true })
  }

  const replaceQueryParameters = parameters => {
    // eslint-disable-next-line no-unused-vars
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true })
    const search = qs.stringify({ token, ...parameters }, { addQueryPrefix: true })

    navigate(`${location.pathname}${search}`, { replace: true })
  }

  const handleClose = () => {
    setIsFiltersOpen(false)
  }

  const handleCloseUploadDialog = () => {
    navigate(`/documents${location.search}`)
  }

  const handleOpen = () => {
    setIsFiltersOpen(true)
  }

  const handleSelect = (parameters, shouldReplace = false) => {
    ;(shouldReplace ? replaceQueryParameters : applyQueryParameters)(parameters)
  }

  const handleToggleMissingDocs = () => {
    applyQueryParameters({ missing: isToggled ? '0' : '1' })
  }

  const documentParam = params.document
  const documents = getDocumentsForFilters(selectedFilters, data)
  const formatter = (slug, search = location.search) => `/documents/${slug}${search}`

  const isDialogOpen = (documentParam && data.docs.some(doc => doc.slug === documentParam)) ?? false
  const filters = getFilters(schema, selectedFilters, formatter, handleSelect, data, userContext)

  const dropdownLabel = getDropdownLabel(filters.slice(0, -1))
  const isToggled = selectedFilters.missing === '1'
  const selectedFilter = filters[0].find(({ isSelected }) => isSelected)

  const sortedDocuments = getSortedDocuments(
    documents,
    formatter,
    userContext,
    selectedFilter?.previousCategoryLastDocumentHref,
    selectedFilter?.nextCategoryFirstDocumentHref
  )

  const documentsGroups = getDocumentsGroups(sortedDocuments)
  const fileSections = documentsGroups.map(formatSection)

  const dropdownFilterProps = {
    filters,
    isOpen: isFiltersOpen,
    label: dropdownLabel,
    onClose: handleClose,
    onOpen: handleOpen,
    onSelect: handleSelect,
  }

  const uploadDialogProps = {
    getNextDocumentHref: slug =>
      sortedDocuments.find(currentDocument => currentDocument.slug === slug)?.nextHref ?? null,
    getPreviousDocumentHref: slug =>
      sortedDocuments.find(currentDocument => currentDocument.slug === slug)?.previousHref ?? null,
    onClose: handleCloseUploadDialog,
  }

  const viewProps = {
    dropdownFilterProps,
    fileSections,
    isDialogOpen,
    isListEmpty: documentsGroups.length === 0,
    isToggled,
    onCloseDialog: handleCloseUploadDialog,
    onToggle: handleToggleMissingDocs,
    onToggleFilters: handleOpen,
    queryParams: location.search,
  }

  return (
    <DocumentsComponent {...viewProps}>
      <Upload {...uploadDialogProps} />
    </DocumentsComponent>
  )
}
