import { getPageSchemaForMortgagor } from '@pretto/move-app-client/src/application/config/mortgagor'
import { UserCircle } from '@pretto/picto'

export const step = {
  condition: (data, { hasComortgagor }) => hasComortgagor,
  documentsParameters: { category: 'general', mortgagor: 1 },
  pages: getPageSchemaForMortgagor(1),
  sideBarParameters: {
    label: (data, { accounts }) => accounts[1].name,
    picto: UserCircle,
    sideBarOrder: 1.5,
    url: '/comortgagor',
  },
}
