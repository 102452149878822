import Text from '@pretto/bricks/components/typography/Text'
import temporal from '@pretto/bricks/core/utility/temporal'

import { formatGroupTitle } from '@pretto/app-core/application/lib/formatGroupTitle'
import * as filters from '@pretto/app-core/lib/filters'

import { Burden } from '@pretto/picto'

import { v4 as uuidv4 } from 'uuid'

const REMAINING_PRINCIPAL_DELAY = 3

const creditTypeOptions = [
  { label: 'Crédit immobilier', value: 'mortgage' },
  { label: 'Crédit conso', value: 'consumer' },
  { label: 'Crédit voiture', value: 'car' },
  { label: 'Crédit étudiant', value: 'student' },
  { label: "Location avec Option d'Achat (LOA, ou leasing)", value: 'LOA' },
  { label: 'Autre type de crédit', value: 'other' },
]

const date = temporal().add(REMAINING_PRINCIPAL_DELAY, 'M')
const month = date.month() + 1
const year = date.year()

const payment = {
  fieldProps: {
    placeholder: '1 000',
    suffix: '€',
  },
  label: 'Mensualité',
  name: 'payment',
  type: 'numberfield',
}

const remainingPrincipal = {
  fieldProps: {
    placeholder: '1 000',
    suffix: '€',
  },
  label: `Capital restant dû au mois de ${formatGroupTitle({ month, year })}`,
  name: 'remaining_principal',
  type: 'numberfield',
}

const endDate = {
  fieldProps: {
    placeholder: 'JJ / MM / AAAA',
  },
  label: 'Date de fin du remboursement',
  name: 'end_date',
  type: 'date',
}

const kind = {
  fieldProps: {
    options: creditTypeOptions,
    placeholder: 'Crédit conso',
  },
  label: 'Type de crédit',
  name: 'kind',
  type: 'select',
}

const linkedProperty = {
  condition: ({ kind }) => kind === 'mortgage',
  fieldProps: (values, project) => ({
    options: [
      ...(project?.profile?.properties?.map(property => ({
        label: property.name,
        value: property.id,
      })) || []),
      { label: 'Créer un bien', value: 'new' },
    ],
  }),
  label: 'Bien associé',
  name: 'property_id',
  type: 'select',
}

const creditFields = [kind, payment, remainingPrincipal, endDate, linkedProperty]

const credits = {
  fieldProps: () => ({
    disclaimer: {
      description: "Si vous n'avez aucun crédit, cette étape est facultative.",
      emoji: ':ok_hand:',
      title: 'Aucun crédit déclaré.',
    },
    formatContent: ({ remaining_principal, payment, bridge }) => {
      if (bridge) {
        return (
          <>
            <Text size="small" variant="accent-1">
              Crédit immobilier de votre bien en vente
            </Text>

            <div>Restant: {remaining_principal} €</div>
          </>
        )
      }

      if (!payment) {
        return 'Aucune mensualité renseignée'
      }

      return `Mensualité : ${payment.toLocaleString('fr')} €`
    },
    isEditable: ({ bridge }) => !bridge,
    isDeletable: ({ bridge }) => !bridge,
    formatTitle: ({ kind, bridge }, index) => {
      const option = creditTypeOptions.find(({ value }) => value === kind)
      if (bridge) {
        return 'Bien à vendre'
      }
      if (!option) {
        return index + 1
      }

      return option.label
    },
  }),
  fields: creditFields,
  name: 'profile.credits',
  type: 'list',
}

const helper =
  "Pour calculer plus précisément votre endettement, nous avons besoin des crédit(s) que vous continuerez à rembourser après votre projet.\n\nConsultez votre tableau d'amortissement pour plus de précision : vous l'avez reçu à la mise en place de votre prêt et vous pouvez le retrouver sur le site internet de votre banque ou en le demandant à votre conseiller."

const creditsSection = {
  fields: [credits],
  helper,
  title: (values, { project: { project_kind } }) =>
    `Vos ${project_kind === 'renegotiation' ? 'autres ' : ''}crédits en cours`,
}

const defaultPage = {
  decoder: values => ({
    ...values,
    'profile.credits': values['profile.credits'].map(credit => ({
      ...credit,
      end_date: filters.formatDecodeDate(credit.end_date),
    })),
  }),
  encoder: values => ({
    'profile.credits': values['profile.credits'].map(credit => ({
      ...credit,
      ...(credit.property_id === 'new' ? { property_id: uuidv4() } : {}),
      end_date: !credit.end_date ? null : filters.formatEncodeDate(credit.end_date),
    })),
  }),
  sections: [creditsSection],
  title: ({ project: { project_kind } }) =>
    `${project_kind === 'renegotiation' ? 'Autres crédits en cours' : 'Crédits en cours'}`,
}

export const step = {
  documentsParameters: {
    category: 'credits',
  },
  pages: [defaultPage],
  sideBarParameters: {
    label: ({ project: { project_kind } }) =>
      `${project_kind === 'renegotiation' ? 'Autres crédits en cours' : 'Crédits en cours'}`,
    picto: Burden,
    sideBarOrder: 6,
    url: '/credits',
  },
}
