import ApplicationPage from '@pretto/bricks/app/application/pages/ApplicationPage'

import { getCards } from '@pretto/app-core/application/lib/getCards'

import { useTracking } from '@pretto/move-app-client/src/analytics/lib/useTracking'
import * as forms from '@pretto/move-app-client/src/application/config'
import { schema } from '@pretto/move-app-client/src/application/config/cards'
import { useApplication } from '@pretto/move-app-client/src/application/lib/useApplication'
import { useUser } from '@pretto/move-app-client/src/user/lib/useUser'

import { useState } from 'react'

export const Application = () => {
  const { data, documentsProgress } = useApplication()

  const userContext = useUser()

  const [switches, setSwitches] = useState({})

  const trackAction = useTracking()

  const handleToggle = id => {
    setSwitches(switches => ({ ...switches, [id]: !switches[id] }))
  }

  const cards = getCards(schema, forms, switches, handleToggle, data, userContext, trackAction)

  return <ApplicationPage cards={cards} documentsProgress={documentsProgress} />
}
