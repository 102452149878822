import { breakpoints } from '@pretto/bricks/components/layout'
import { bodyBook16, heading32 } from '@pretto/bricks/core/typography'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import { Button as ButtonComponent } from '@pretto/zen/atoms/buttons/Button/Button'

import libphonenumber from 'google-libphonenumber'
import styled from 'styled-components'

import { Banner } from './banner'

const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance()

export const formatEncodePhone = (phone: string) => {
  const parsedPhone = phoneNumberUtil.parse(phone, 'FR')

  return phoneNumberUtil.format(parsedPhone, libphonenumber.PhoneNumberFormat.E164)
}

interface LoginViewProps {
  phoneFieldComponent: React.ReactNode
  bannerMessage?: string | null
  isLoading: boolean
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const Login: React.FC<LoginViewProps> = ({ phoneFieldComponent, bannerMessage, isLoading, onSubmit }) => {
  const BACKGROUND_WIDTH = 600
  const BACKGROUND_HEIGHT = 240

  return (
    <View>
      <Frame>
        <img
          src={getCloudinaryUrl('v1696923446/zen/ui-assets/image-dossier.png', {
            width: BACKGROUND_WIDTH,
            height: BACKGROUND_HEIGHT,
          })}
        />
        <Section>
          <Title>Mon espace document sécurisé</Title>

          <Paragrah>
            Notre niveau de sécurité augmente ! Votre numéro de téléphone va maintenant servir d’authentification pour
            accéder à votre dossier.
          </Paragrah>

          <Form noValidate onSubmit={onSubmit}>
            {phoneFieldComponent}
            {bannerMessage && <Banner value={bannerMessage} />}
            <Button isArrowShown isLoading={isLoading}>
              Continuer
            </Button>
          </Form>
        </Section>
      </Frame>
    </View>
  )
}

const Paragrah = styled.p`
  ${bodyBook16};

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`

const Title = styled.h2`
  ${heading32};

  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;

  @media screen and (min-width: ${breakpoints.tablet}) {
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
  }
`

const Button = styled(ButtonComponent)``

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;

  & > div {
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
  }

  & input {
    background-color: #f5f5f5;
  }
`

const Section = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    max-width: 600px;
    min-width: 600px;
  }

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`

const View = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
`
