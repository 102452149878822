import { TokenStorage } from '@pretto/move-app-client/src/auth/lib/helpers'

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const cache = new InMemoryCache({
  typePolicies: {
    DocsList: {
      fields: {
        meta: {
          merge: true,
        },
      },
    },
    Facts: {
      fields: {
        bridge_credit: {
          merge: true,
        },
      },
    },
    Good: {
      fields: {
        localisation: {
          merge: true,
        },
      },
    },
    Immovable: {
      fields: {
        localisation: {
          merge: true,
        },
      },
    },
    Job: {
      fields: {
        address: {
          merge: true,
        },
      },
    },
    Mortgagor: {
      fields: {
        address: {
          merge: true,
        },
        birthplace: {
          merge: true,
        },
        bonus: {
          merge: true,
        },
        job: {
          merge: true,
        },
        salary: {
          merge: true,
        },
      },
    },
    Project: {
      fields: {
        facts: {
          merge: true,
        },
        good: {
          merge: true,
        },
        profile: {
          merge: true,
        },
        purchase: {
          merge: true,
        },
        renegotiation: {
          merge: true,
        },
      },
    },
    Purchase: {
      fields: {
        bridge: {
          merge: true,
        },
      },
    },
  },
})

const httpLink = createHttpLink({
  uri: API_HOST,
})

const middlewareLink = setContext((request, { headers = {} }) => ({
  headers: {
    Authorization: `Bearer ${TokenStorage.getToken()}`,
    ...headers,
  },
}))

const link = middlewareLink.concat(httpLink)

export const client = new ApolloClient({ cache, link })
