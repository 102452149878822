import NavigationLinkProvider from '@pretto/bricks/components/utility/NavigationLinkProvider'

import PropTypes from 'prop-types'
import { forwardRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

const NavigationLink = forwardRef(function NavigationLink({ href, ...props }, ref) {
  return <Link {...props} ref={ref} to={href} />
})

NavigationLink.propTypes = {
  href: PropTypes.string.isRequired,
}

export const Layout = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <NavigationLinkProvider linkComponent={NavigationLink} location={location} persistingSearchParams={['token']}>
      {children}
    </NavigationLinkProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
