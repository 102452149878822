import { SentenceFieldSimple } from '@pretto/zen/atoms/sentenceFields/SentenceFieldSimple/SentenceFieldSimple'
import { TextField } from '@pretto/zen/atoms/textFields/TextField/TextField'

import { useState } from 'react'

import { UseField, useFieldController } from './useFieldController'

export const useTextField: UseField = ({ defaultValue = '', inputProps, type = 'block', validators, ...props }) => {
  const [value, setValue] = useState(defaultValue)

  const { stateProps, ...fieldControls } = useFieldController(value, inputProps, validators)

  const handleChange = (value: string) => {
    setValue(value)
  }

  const fieldProps = {
    ...props,
    ...stateProps,
    inputProps,
    onChange: handleChange,
    value,
  }

  const component = type === 'block' ? <TextField {...fieldProps} /> : <SentenceFieldSimple {...fieldProps} />

  return { ...fieldControls, component, setValue, value }
}
