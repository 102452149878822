import { breakpoints, g } from '@pretto/bricks/components/layout'
import { heading32 } from '@pretto/bricks/core/typography'

import { Avatar } from '@pretto/zen/atoms/avatars/Avatar/Avatar'
import { Button as ButtonComponent } from '@pretto/zen/atoms/buttons/Button/Button'
import { ButtonBack as ButtonBackComponent } from '@pretto/zen/atoms/buttons/ButtonBack/ButtonBack'

import { useTextField } from '@pretto/move-app-client/src/lib/form/useTextField'
import { Smartphone } from '@pretto/picto'

import styled from 'styled-components'

import { useAuth } from '../containers/context'

import { Banner } from './banner'

export interface LoginCheckViewProps {
  bannerMessage?: string | null
  onSubmit: ({ code }: { code: string }) => void
  onRetry: () => void
  onBack: () => void
  error: 'invalid' | 'expired' | null
  isLoading: boolean
  isRetrying: boolean
}

export const LoginCheck: React.FC<LoginCheckViewProps> = ({
  onBack,
  onSubmit,
  onRetry,
  error,
  isLoading,
  isRetrying,
  bannerMessage,
}) => {
  const { phone } = useAuth()

  const stateAndMessange = {
    invalid: ['error', 'Code de vérification incorrect. Veuillez réessayer.'],
    expired: ['error', 'Code expiré. Veuillez demander l’envoi d’un nouveau code en cliquant sur “Réessayer”.'],
  } as const

  const [state, message] = error ? stateAndMessange[error] : [undefined, undefined]

  const {
    check: codeFieldCheck,
    component: codeFieldComponent,
    value: code,
  } = useTextField({
    inputProps: {
      autoFocus: true,
      placeholder: 'Entrer le code de vérification',
      required: true,
    },
    state,
    message,
    placeholderAsLabel: true,
    size: 'large',
    validators: [],
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const isValid = [codeFieldCheck()].every(Boolean)
    if (!isValid) {
      return
    }

    onSubmit({ code })
  }

  return (
    <View>
      <Section>
        <Avatar backgroundColor={'neutral4'} src={<Smartphone />} />
        <Title>Code de vérification</Title>
        <Text>
          Un SMS a été envoyé au <Phone>{phone}</Phone> avec un code de vérification
        </Text>

        <Form noValidate onSubmit={handleSubmit}>
          {codeFieldComponent}
          <Text>
            <span>{"Vous n'avez rien reçu ? "}</span>
            <Link $isRetrying={isRetrying} onClick={onRetry}>
              Réessayez
            </Link>
          </Text>
          {bannerMessage && <Banner value={bannerMessage} />}
          <ButtonFrame>
            <ButtonBack isArrowShown onClick={onBack}>
              Retour
            </ButtonBack>
            <Button isArrowShown isLoading={isLoading}>
              Continuer
            </Button>
          </ButtonFrame>
        </Form>
      </Section>
    </View>
  )
}

const Phone = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.32px;
`

const Text = styled.p`
  font-family: Maison Neue;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  text-decoration: none;
`

const Link = styled.a<{ $isRetrying?: boolean }>`
  font-family: Maison Neue;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  color: ${props => (props.$isRetrying ? '#000000;' : '#fc5c63;')}};
  cursor: ${props => (props.$isRetrying ? 'wait;' : 'pointer')}};
  text-decoration: underline;
`

const Title = styled.h2`
  ${heading32};
  margin-bottom: ${g(5)};

  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;

  @media screen and (min-width: ${breakpoints.tablet}) {
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
  }
`

const Button = styled(ButtonComponent)``
const ButtonBack = styled(ButtonBackComponent)``

const ButtonFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  & > div {
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
  }

  input {
    background-color: #f5f5f5;
  }
`

const Section = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    min-width: 568px;
    max-width: 568px;
  }
`

const View = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
    min-height: 100%;
  }
`
