import * as forms from '@pretto/move-app-client/src/application/config'
import { Application } from '@pretto/move-app-client/src/application/containers/application'
import { Provider as ApplicationProvider } from '@pretto/move-app-client/src/application/containers/applicationContext'
import { Documents } from '@pretto/move-app-client/src/application/containers/documents'
import { Form } from '@pretto/move-app-client/src/application/containers/form'
import { Layout } from '@pretto/move-app-client/src/application/containers/layout'
import { Provider as UploadProvider } from '@pretto/move-app-client/src/application/containers/uploadContext'
import { useAuth } from '@pretto/move-app-client/src/auth/containers/context'
import { Login } from '@pretto/move-app-client/src/auth/containers/login'
import { LoginCheck } from '@pretto/move-app-client/src/auth/containers/loginCheck'
import { NotFound } from '@pretto/move-app-client/src/error/containers/notFound'
import { Provider as UserProvider } from '@pretto/move-app-client/src/user/containers/context'

import { Navigate, Route, Routes } from 'react-router-dom'

export const Router = () => {
  const { isLoggedIn } = useAuth()

  if (!isLoggedIn)
    return (
      <Routes>
        <Route element={<LoginCheck />} path="/loginCheck" />
        <Route element={<Login />} path="/login" />
        <Route element={<Navigate to="/login" replace={true} />} path="/*" />
      </Routes>
    )

  return (
    <UserProvider>
      <ApplicationProvider>
        <UploadProvider>
          <Routes>
            <Route element={<Login />} path="/logout" />
            <Route element={<Application />} index path="/" />
            <Route element={<Layout />}>
              <Route element={<Documents />} path="/documents/:document?" />
              {Object.keys(forms).map(id => (
                <Route element={<Form stepId={id} />} key={id} path={`/${id}`} />
              ))}
            </Route>
            <Route element={<NotFound />} path="/*" />
          </Routes>
        </UploadProvider>
      </ApplicationProvider>
    </UserProvider>
  )
}
