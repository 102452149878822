import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const PageTracker = () => {
  const location = useLocation()

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // analytics.page()
    }
  }, [location])

  return null
}
