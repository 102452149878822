import type { SentenceFieldSimpleProps } from '@pretto/zen/atoms/sentenceFields/SentenceFieldSimple/SentenceFieldSimple'
import type { TextFieldProps } from '@pretto/zen/atoms/textFields/TextField/TextField'

import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { StateProps, validate, Validator } from './validate'

export type FieldInputProps =
  | (React.InputHTMLAttributes<HTMLInputElement> & { format?: string; type: 'date' })
  | (React.InputHTMLAttributes<HTMLInputElement> & { format?: never })

const DEBOUNCE_RATE = 1000

export interface FieldControls {
  check: () => boolean
  invalidate: (invalidatingReason: string) => void
}

export interface FieldState {
  error: boolean
  stateProps: StateProps | null
}

type UseFieldParams = {
  defaultValue?: string
  inputProps?: FieldInputProps
  validators?: Validator[]
}

type UseFieldReturn = FieldControls &
  Omit<FieldState, 'stateProps'> & {
    component: React.ReactNode
    setValue: React.Dispatch<React.SetStateAction<string>>
    value: string
  }

export type UseField = {
  (params: UseFieldParams & { type: 'block' } & Omit<TextFieldProps, 'onChange' | 'value'>): UseFieldReturn
  (params: UseFieldParams & { type: 'inline' } & Omit<SentenceFieldSimpleProps, 'onChange' | 'value'>): UseFieldReturn
  (params: UseFieldParams & { type?: 'block' | 'inline' } & Omit<TextFieldProps, 'onChange' | 'value'>): UseFieldReturn
}

type UseFieldController = (
  value: string,
  inputProps?: FieldInputProps,
  validators?: Validator[]
) => FieldControls & FieldState

export const useFieldController: UseFieldController = (value, inputProps = {}, validators = []) => {
  const [debouncedValue] = useDebounce(value, DEBOUNCE_RATE)

  const [isCheckRequested, setIsCheckRequested] = useState(false)
  const [invalidatingReason, setInvalidatingReason] = useState<string | null>(null)

  useEffect(() => {
    setInvalidatingReason(null)
  }, [value])

  const invalidate = (invalidatingReason: string) => {
    setInvalidatingReason(invalidatingReason)
  }

  const check = () => {
    setIsCheckRequested(true)

    return validate(validators, value, debouncedValue, true, inputProps, invalidatingReason).isValid
  }

  const { isValid, stateProps } = validate(
    validators,
    value,
    debouncedValue,
    isCheckRequested,
    inputProps,
    invalidatingReason
  )

  return {
    check,
    error: !isValid,
    invalidate,
    stateProps,
  }
}
