import ApplicationFormPage from '@pretto/bricks/app/application/pages/ApplicationFormPage'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { decodeValuesForStep } from '@pretto/app-core/application/lib/decodeValuesForStep'
import { encodeSectionsValues } from '@pretto/app-core/application/lib/encodeSectionsValues'
import { getCurrentPageForId } from '@pretto/app-core/application/lib/getCurrentPageForId'
import { getCurrentStepForId } from '@pretto/app-core/application/lib/getCurrentStepForId'
import { getDocumentsParametersForStep } from '@pretto/app-core/application/lib/getDocumentsParametersForStep'
import { getDocumentsStatusForStep } from '@pretto/app-core/application/lib/getDocumentsStatusForStep'
import { reduceField } from '@pretto/app-core/application/lib/reduceField'
import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'
import { isFieldValid } from '@pretto/app-core/lib/isFieldValid'
import { renderSections } from '@pretto/app-core/lib/renderSections'
import { resetValuesForDisabledFields } from '@pretto/app-core/lib/resetValuesForDisabledFields'

import * as forms from '@pretto/move-app-client/src/application/config'
import { useApplication } from '@pretto/move-app-client/src/application/lib/useApplication'
import { useUser } from '@pretto/move-app-client/src/user/lib/useUser'

import { gql, useApolloClient } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import path from 'path'
import PropTypes from 'prop-types'
import qs from 'qs'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const isSubmitDisabled = (errors, sections, values, data, userContext, optional = true) =>
  reduceField(
    sections,
    (previous, value) => previous || (!optional && !isFieldValid(value)),
    Object.values(errors).some(error => error === true),
    values,
    data,
    userContext
  )

const Page = ({ documentsHref, documentsStatusBySection, hasNextPage, onTerminate, schema, title }) => {
  const client = useApolloClient()

  const { data } = useApplication()

  const location = useLocation()

  const defaultValues = useRef(null)

  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})
  const [mutationLoading, setMutationLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [values, setValues] = useState(null)

  const isToSave = !isEqual(defaultValues.current, values)

  const userContext = useUser()

  useEffect(() => {
    const initializeValues = async () => {
      const decodedValues = await decodeValuesForStep(schema.decoder, sections, data, userContext)
      const resetValues = resetValuesForDisabledFields(decodedValues, sections, data.project, userContext)

      defaultValues.current = resetValues
      setError(null)
      setErrors({})
      setMutationLoading(false)
      setValues(resetValues)
    }

    initializeValues()
  }, [schema.id])

  const sections = funcToItem(schema.sections, data, userContext)

  const handleSave = async () => {
    setUpdateLoading(true)
    const encodedSections = await encodeSectionsValues(schema.encoder, sections, values, defaultValues.current, data)

    setError(null)

    const mutations = encodedSections.map(encodedSection => {
      if (!encodedSection) {
        return Promise.resolve()
      }

      const { mutation, values } = encodedSection

      if (!mutation) {
        const clonedValues = cloneDeep(values)
        const mortgagorID = get(data.project, 'profile.mortgagors[0].id')
        const comortgagorID = get(data.project, 'profile.mortgagors[1].id')

        set(clonedValues, 'profile.mortgagors[0].id', mortgagorID)
        if (userContext.hasComortgagor) set(clonedValues, 'profile.mortgagors[1].id', comortgagorID)

        return new Promise(resolve =>
          client.mutate({
            mutation: updateProject,
            update: resolve,
            variables: { project: JSON.stringify(clonedValues) },
          })
        )
      }

      const formattedMutation = funcToItem(mutation, values, data, userContext)

      return new Promise((resolve, reject) => {
        client.mutate({
          ...formattedMutation,
          update: (cache, response) => {
            const error = formattedMutation.error?.(response)

            if (error) {
              setError(error)
              setMutationLoading(false)
              reject()
              return
            }

            resolve()
          },
        })
      })
    })

    if (mutations.length === 0) {
      setUpdateLoading(false)
      return onTerminate()
    }

    await Promise.all(mutations)
    await invalidateCache(client)

    defaultValues.current = values

    setUpdateLoading(false)
  }

  const handleSubmit = async () => {
    const encodedSections = await encodeSectionsValues(schema.encoder, sections, values, defaultValues.current, data)

    await handleSave()
    onTerminate(encodedSections)
  }

  const handleChange = (name, value, error) => {
    setErrors(errors => ({ ...errors, [name]: funcToItem(error, data.project, userContext) }))
    setValues(values => resetValuesForDisabledFields({ ...values, [name]: value }, sections, data.project, userContext))
  }

  if (!values || mutationLoading) {
    return <SpinnerLegacy overlay />
  }

  return (
    <ApplicationFormPage
      currentHref={location.pathname}
      documentsHref={documentsHref}
      documentsStatusBySection={documentsStatusBySection}
      error={error}
      hasNextPage={hasNextPage}
      isButtonVisible={isToSave}
      isLoading={updateLoading}
      isSubmitDisabled={isSubmitDisabled(errors, sections, values, data, userContext, schema.optional)}
      onSubmit={handleSubmit}
      sections={renderSections(sections, handleChange, values, data, userContext)}
      title={title}
    />
  )
}

Page.propTypes = {
  documentsHref: PropTypes.string.isRequired,
  documentsStatusBySection: PropTypes.oneOf(['idle', 'invalid', 'complete']),
  hasNextPage: PropTypes.bool,
  onTerminate: PropTypes.func.isRequired,
  schema: PropTypes.shape({
    decoder: PropTypes.func,
    encoder: PropTypes.func,
    id: PropTypes.string,
    optional: PropTypes.bool,
    sections: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
}

export const Form = ({ stepId }) => {
  const { data } = useApplication()

  const location = useLocation()

  const navigate = useNavigate()

  const userContext = useUser()

  const step = getCurrentStepForId(forms, stepId)
  const page = getCurrentPageForId(step)

  const title = funcToItem(page.title, data, userContext)

  const handleTerminate = (values = []) => {
    const href = funcToItem(page.jumps, data.project, userContext, location.search ?? '', values) ?? ''
    const absolutePath = path.resolve(stepId, href)
    const redirect = qs.parse(location.search, { ignoreQueryPrefix: true })?.redirect

    navigate(redirect ?? absolutePath)
  }

  const documentsParameters = getDocumentsParametersForStep(step, data, userContext)
  const hasNextPage = funcToItem(page.hasNextPage, data, userContext)

  return (
    <Page
      key={location.pathname}
      documentsHref={`/documents?${qs.stringify(documentsParameters)}`}
      documentsStatusBySection={getDocumentsStatusForStep(step, data, userContext)}
      hasNextPage={hasNextPage}
      onTerminate={handleTerminate}
      schema={page}
      title={title}
    />
  )
}

Form.propTypes = {
  stepId: PropTypes.oneOf(Object.keys(forms)),
}

const updateProject = gql`
  mutation UpdateProject($project: String!) {
    update_project(project: $project) {
      error
      success
    }
  }
`
