import App from '@pretto/move-app-client/src/App'
import '@pretto/move-app-client/src/root.css'

import 'core-js/stable'
import ReactDOM from 'react-dom'
import 'regenerator-runtime/runtime'

if (process.env.NODE_ENV === 'development') {
  // analytics.load(SEGMENT_KEY)
}

ReactDOM.render(<App />, document.getElementById('root__body'))
