import { Avatar } from '@pretto/zen/atoms/avatars/Avatar/Avatar'

import { ExclamationMarkCircle } from '@pretto/picto'

import styled from 'styled-components'

type BannerProps = {
  value: string
}

export const Banner: React.FC<BannerProps> = ({ value }) => (
  <Card>
    <Avatar color={'error1'} backgroundColor={'error2'} src={<ExclamationMarkCircle />} />
    <Text>{value}</Text>
  </Card>
)

const Text = styled.p`
  color: var(--error-1, #c70808);

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const Card = styled.div`
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--error-2, #ffd0d0);
`
