import { Layout as LayoutComponent } from '@pretto/move-app-client/src/application/components/Layout/Layout'
import { useApplication } from '@pretto/move-app-client/src/application/lib/useApplication'

import { Outlet, useLocation } from 'react-router-dom'

export const Layout = () => {
  const { navigationItems } = useApplication()

  const { pathname } = useLocation()

  return (
    <LayoutComponent navigationItems={navigationItems} pathname={pathname}>
      <Outlet />
    </LayoutComponent>
  )
}
