import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { useAuth } from '@pretto/move-app-client/src/auth/containers/context'
import { decodeToken } from '@pretto/move-app-client/src/auth/lib/helpers'

import { gql, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { createContext } from 'react'
import { useNavigate } from 'react-router-dom'

export const Context = createContext()

export const Provider = ({ children }) => {
  const navigate = useNavigate()
  const { token } = useAuth()
  const { data, loading } = useQuery(query)

  if (!token) {
    navigate('/login', { replace: true })
  }

  useLoading(loading)

  if (loading) {
    return null
  }

  const value = {
    accounts: data.project.profile.mortgagors,
    mainMortgagor: data.accounts.find(({ isMine }) => isMine),
    get hasComortgagor() {
      return this.accounts.length > 1
    },
    projectId: decodeToken(token).sub,
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

const query = gql`
  query userContext {
    accounts {
      id
      isMine
    }
    project {
      id
      profile {
        mortgagors {
          firstName: first_name
          id
          lastName: last_name
          name
        }
      }
    }
  }
`
