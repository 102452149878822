import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { FileItemState } from '@pretto/app-core/application/components/FileItem/FileItem'
import { UploadDialog } from '@pretto/app-core/application/components/UploadDialog/UploadDialog'
import { ALLOWED_EXTENSIONS, ALLOWED_MIME_TYPES, MAX_SIZE } from '@pretto/app-core/application/config/constants'
import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'
import { getFileExtension } from '@pretto/app-core/application/lib/getFileExtension'
import { getFiles } from '@pretto/app-core/application/lib/getFiles'
import { getMortgagorFullname } from '@pretto/app-core/application/lib/getMortgagorFullname'
import { UploadFileStatus } from '@pretto/app-core/application/lib/useUpload'

import BANKS from '@pretto/config/banks.json'
import DOCS from '@pretto/config/docs.json'
import { useTracking } from '@pretto/move-app-client/src/analytics/lib/useTracking'
import { useApplication } from '@pretto/move-app-client/src/application/lib/useApplication'
import { useApplicationUpload } from '@pretto/move-app-client/src/application/lib/useApplicationUpload'
import { useUser } from '@pretto/move-app-client/src/user/lib/useUser'

import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Markdown from 'react-markdown'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'

export const Upload = ({ getPreviousDocumentHref, getNextDocumentHref, onClose }) => {
  const { data } = useApplication()

  const { files, onDelete, onUpload } = useApplicationUpload()

  const { document } = useParams()

  const [documentSlug, setDocumentSlug] = useState(document)

  const trackAction = useTracking()

  const handleDrop = (droppedFiles, rejectedFiles, event) => {
    if (!currentDocument) {
      return
    }

    const existingFiles = files.filter(({ slug }) => slug === currentDocument.slug)

    const signedFiles = getFiles(droppedFiles, existingFiles)

    onUpload(currentDocument, signedFiles)

    signedFiles.forEach(signedFile => {
      trackAction('DOCUMENTS_FILEDROP_TRACKED', {
        documents_filedrop_category: DOCS[kind].category,
        documents_filedrop_id: id,
        documents_filedrop_kind: kind,
        documents_filedrop_method: event.type === 'drop' ? event.type : 'browse',
        documents_filedrop_mime_type: signedFile.type,
        documents_filedrop_month: meta.month,
        documents_filedrop_mortgagor: meta.mortgagor,
        documents_filedrop_size: signedFile.size,
        documents_filedrop_year: meta.year,
      })
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ALLOWED_MIME_TYPES,
    maxSize: MAX_SIZE,
    noClick: true,
    onDrop: handleDrop,
  })

  useEffect(() => {
    if (!document) {
      return
    }

    trackAction('DOCUMENTS_FILEDROP_MODAL_OPENED', {
      document,
    })

    setDocumentSlug(document)
  }, [document])

  const { accounts } = useUser()

  if (!documentSlug) {
    return null
  }

  const currentDocument = data.docs?.find(document => document.slug === documentSlug)

  if (!currentDocument) {
    return <Redirect to="/404" />
  }

  const { title: docTitle, document_status, error_message, id, kind, meta, slug } = currentDocument

  const docDescription = currentDocument.docDescription ? <Markdown>{currentDocument.docDescription}</Markdown> : null
  const docExplanation = kind !== 'other' ? <Markdown>{DOCS[kind].explanation}</Markdown> : null

  const errorMessage = convertStatusSlug(document_status) === 'invalid' ? error_message : null

  const titleBank = BANKS.find(({ value }) => value === meta?.bank)?.label ?? null
  const titleKind = kind === 'other' && slug !== 'other' ? docTitle : DOCS[kind].name
  const title = [titleKind, titleBank].filter(Boolean).join(' - ')

  const processedFiles = files.reduce((previous, file) => {
    if (file.slug !== slug || previous.some(({ id }) => file?.remoteId === id)) {
      return previous
    }

    const base = {
      extension: getFileExtension(file.fileName),
      fileName: file.fileName,
      id: file.localId,
    }

    switch (file.status) {
      case UploadFileStatus.Error:
        return [
          ...previous,
          {
            ...base,
            onRetry: file.retry,
            state: FileItemState.Error,
          },
        ]

      case UploadFileStatus.Removing:
        return [
          ...previous,
          {
            ...base,
            date: file.uploadedAt,
            state: FileItemState.Removing,
          },
        ]

      case UploadFileStatus.Stale:
        return [
          ...previous,
          {
            ...base,
            date: file.uploadedAt,
            onHelp: () => {},
            state: FileItemState.Stale,
          },
        ]

      case UploadFileStatus.Uploaded:
        return [
          ...previous,
          {
            ...base,
            date: file.uploadedAt,
            onDelete() {
              onDelete(currentDocument, file)
            },
            state: FileItemState.Uploaded,
          },
        ]

      case UploadFileStatus.Uploading:
        return [
          ...previous,
          {
            ...base,
            progress: file.progress * 100,
            state: FileItemState.Uploading,
          },
        ]

      default:
        return previous
    }
  }, [])

  const dialogProps = {
    description: docDescription,
    errorMessage,
    explanation: docExplanation,
    files: processedFiles,
    fileOwnershipLabelProps: {
      names: getMortgagorFullname(meta, accounts),
    },
    isDragging: isDragActive,
    nextHref: getNextDocumentHref(slug),
    onClose,
    previousHref: getPreviousDocumentHref(slug),
    title,
    uploadAreaProps: {
      allowedExtensions: ALLOWED_EXTENSIONS,
      inputProps: getInputProps({ style: undefined, tabIndex: undefined }),
      maxSize: `${MAX_SIZE / 1e6} Mo`,
    },
  }

  return (
    <ThemeProvider designSystem="reveal">
      <div {...getRootProps()}>
        <UploadDialog key={slug} {...dialogProps} />
      </div>
    </ThemeProvider>
  )
}

Upload.propTypes = {
  getPreviousDocumentHref: PropTypes.func,
  getNextDocumentHref: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}
