import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { Provider as LoadingProvider } from '@pretto/app-core/loading/containers/context'
import { NotificationsProvider } from '@pretto/app-core/notifications/notifications'

import { PageTracker } from '@pretto/move-app-client/src/analytics/containers/pageTracker'
import { client } from '@pretto/move-app-client/src/api/lib/client'
import { Router as ApplicationRouter } from '@pretto/move-app-client/src/application/containers/router'
import { Provider as AuthProvider } from '@pretto/move-app-client/src/auth/containers/context'
import { Layout } from '@pretto/move-app-client/src/components/Layout/Layout'
import { Handler as ErrorHandler } from '@pretto/move-app-client/src/error/containers/handler'

import { ApolloProvider } from '@apollo/client'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

const App = () => (
  <ThemeProvider>
    <ErrorHandler>
      <LoadingProvider loaderComponent={<SpinnerLegacy overlay />}>
        <Router>
          <AuthProvider>
            <ApolloProvider client={client}>
              <NotificationsProvider>
                <Layout>
                  <Routes>
                    <Route element={<ApplicationRouter />} index path="/*" />
                  </Routes>
                  <PageTracker />
                </Layout>
              </NotificationsProvider>
            </ApolloProvider>
          </AuthProvider>
        </Router>
      </LoadingProvider>
    </ErrorHandler>
  </ThemeProvider>
)

export default App
