import { InternalError } from '@pretto/move-app-client/src/error/components/InternalError/InternalError'

import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import { Component } from 'react'

const isProduction = process.env.NODE_ENV === 'production'

if (isProduction) {
  Sentry.init({
    debug: process.env.CONTEXT !== 'production',
    dsn: process.env.SENTRY_DATA_SOURCE_NAME,
    environment: process.env.CONTEXT,
    release: process.env.COMMIT_REF?.substring(0, 7),

    tracesSampleRate: 0.1,
  })
}

export class Handler extends Component {
  static propTypes = {
    children: PropTypes.node,
  }

  state = { isThrown: false }

  componentDidCatch(error, info) {
    if (isProduction) {
      Sentry.withScope(scope => {
        scope.setExtra('componentStack', info)

        Sentry.captureException(error)
      })
    } else {
      // eslint-disable-next-line no-console
      console.log(error)
    }

    this.setState({ isThrown: true })
  }

  render() {
    if (!this.state.isThrown) {
      return this.props.children
    }

    return <InternalError isRefreshable />
  }
}
