import { decodeToken, getImpersonationToken, TokenStorage } from '@pretto/move-app-client/src/auth/lib/helpers'

import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type IContext = {
  isLoggedIn: boolean
  logout: () => void
  setPhone: (phone: string) => void
  setToken: (token: string | null) => void
  phone: string | null
  token: string | null
}

const defaultContext: IContext = {
  isLoggedIn: false,
  logout: () => {},
  phone: null,
  setPhone: () => {},
  setToken: () => {},
  token: null,
}

export const Context = createContext<IContext>(defaultContext)

export const useAuth = () => useContext<IContext>(Context)

const tokenStorage = TokenStorage.build(getImpersonationToken())

export type ProviderProps = PropsWithChildren<unknown>

export const Provider: React.FC<ProviderProps> = ({ children }) => {
  const navigate = useNavigate()
  const [token, setToken] = useState<string | null>(tokenStorage.getToken())
  const [phone, setPhone] = useState<string | null>(null)

  const logout = useCallback(() => {
    setToken(null)
  }, [setToken])

  useEffect(() => {
    if (token) {
      if (getImpersonationToken() !== null) {
        return navigate('/', { replace: true })
      }

      const decoded = decodeToken(token)

      if (Date.now() > decoded.exp * 1000) {
        setToken(null)
      }
    }

    // if (process.env.NODE_ENV === 'production') {
    //   analytics.identify(getAccountId())
    // }
  }, [setToken, token])

  // Handles token storage
  useEffect(() => {
    if (token) {
      tokenStorage.setToken(token)
    } else {
      tokenStorage.clearToken()
    }
  }, [token, tokenStorage])

  const value: IContext = {
    isLoggedIn: token !== null && token !== undefined,
    token,
    setToken,
    phone,
    setPhone,
    logout,
  }
  return <Context.Provider value={value}>{children}</Context.Provider>
}
